import React from 'react';
import PropTypes from 'prop-types';

import NoImg from "../../images/blank_profile.png"

// Material UI

import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
    paper: {
        padding: 20,
        height: 'auto'
      },
      profile: {
        '& .image-wrapper': {
          textAlign: 'center',
          position: 'relative',
        },
        '& .profile-image': {
          width: 150,
          height: 150,
          objectFit: 'cover',
          maxWidth: '100%',
          borderRadius: '50%'
        },
        '& .profile-details': {
          textAlign: 'center',
          '& span, svg': {
            verticalAlign: 'middle'
          },
          '& a': {
            color: theme.palette.primary.main
          }
        },
        '& hr': {
          border: 'none',
          margin: '0 0 10px 0'
        },
      },
    halfLine: {
        width: '40%',
        height: 15,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        marginBottom: '10px'
    },
});

const ProfileSkelton = (props) => {
    const { classes } = props;

    return (
        <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                    <div className={classes.profile}>
                      <div className="image-wrapper">
                            <img src={NoImg} alt="profile" className="profile-image"/>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <div className={classes.profile}>
                        <div className="profile-details">
                            <div className={classes.halfLine} />
                            <hr />
                            <div className={classes.halfLine} />
                            <hr />
                            <div className={classes.halfLine} />
                            <hr />
                            <div className={classes.halfLine} />
                            <hr />
                            <div className={classes.halfLine} />
                        </div>
                      </div>
                    </Grid>
                </Grid>
        </Paper>
    );
}

ProfileSkelton.propTypes = {
    classes: PropTypes.object.isRequired
}
 
export default withStyles(styles)(ProfileSkelton);