import { SET_WRITEUPS, LOADING_DATA, LIKE_WRITEUP, UNLIKE_WRITEUP, SET_DRAFTS, DELETE_WRITEUP, POST_WRITEUP, POST_DRAFT, SET_WRITEUP, SUBMIT_COMMENT, DELETE_DRAFT, SET_DRAFT, MOVE_DRAFT, DELETE_COMMENT, SET_NOTE, POST_NOTE, DELETE_NOTE, SET_NEWWRITEUPS} from "../types"


const initalState = {
    writeups: [],
    writeup: {},
    drafts: [],
    draft: {},
    notes: [],
    loading: false
};

export default function(state = initalState, action){
    switch(action.type){

        case LOADING_DATA:
            return {
                ...state,
                loading: true
            };

        case SET_WRITEUPS:
            return {
                ...state,
                writeups: action.payload,
                loading: false
            };

        case SET_NEWWRITEUPS:
                let result = state.writeups.concat(action.payload)
            return {
                ...state,
                writeups: result,
            };
        case SET_WRITEUP:
            
            return {
                ...state,
                writeup: action.payload
            };

        case SET_DRAFTS:
            return {
                ...state,
                drafts: action.payload,
                loading: false
            };

        case SET_DRAFT:
            return {
                ...state,
                draft: action.payload
            }

        case LIKE_WRITEUP:
        case UNLIKE_WRITEUP:
            let index = state.writeups.findIndex((writeup) => writeup.writeUpId === action.payload.writeUpId);
            state.writeups[index] = action.payload;
            if(state.writeup.writeUpId === action.payload.writeUpId){
                state.writeup = action.payload
            }
            return {
                ...state
            };

        case DELETE_WRITEUP:
            let deleteIndex = state.writeups.findIndex((writeup) => writeup.writeUpId === action.payload.writeUpId);
            state.writeups.splice(deleteIndex, 1);
            return {
                ...state
            };

            case DELETE_DRAFT:
            let deleteDraftIndex = state.drafts.findIndex((draft) => draft.draftId === action.payload.draftId);
            state.drafts.splice(deleteDraftIndex, 1);
            return {
                ...state
            };

        case POST_WRITEUP:
            return{
                ...state,
                writeups: [
                    action.payload,
                    ...state.writeups
                ]
            };

            case POST_DRAFT:
            return{
                ...state,
                drafts: [
                    action.payload,
                    ...state.drafts
                ]
            };

            case MOVE_DRAFT:
            return{
                ...state,
                writeups: [
                    action.payload,
                    ...state.writeups
                ]
            };
            case SUBMIT_COMMENT:
                return {
                    ...state,
                    writeup:{
                        ...state.writeup,
                        comments: [action.payload, ...state.writeup.comments]
                    }
                };
            case  DELETE_COMMENT:
                let deleteComment = state.writeup.comments.findIndex((comment) => comment.commentId === action.payload.commentId);
                state.writeup.comments.splice(deleteComment, 1);
                return {
                    ...state
                };
            case SET_NOTE:
                return {
                    ...state,
                    notes: action.payload,
                    loading: false 
                };
            case  POST_NOTE:
                return {
                    ...state,
                    notes: [
                        action.payload,
                        ...state.notes,
                    ]
                }
            case  DELETE_NOTE:
                let deleteNote = state.notes.findIndex((note) => note.noteId === action.payload.noteId);
                state.notes.splice(deleteNote, 1);
                return {
                    ...state,
                };
        default:
            return state;
    }
}