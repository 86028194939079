import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import WriteUp from "../components/writeup/WriteUp"
import StaticProfile from "../components/profile/StaticProfile"
import ProfileSkelton from '../utils/skeltons/ProfileSkelton';
import WriteUpSkelton from "../utils/skeltons/WriteUpSkelton"

//Material-UI
import Grid from '@material-ui/core/Grid';

//Redux
import { connect } from 'react-redux';
import { getUserData } from "../redux/actions/dataActions"

class User extends Component {
    state={
        profile: null,
        writeUpIdParam: null
    }
    componentDidMount(){
        const userName = this.props.match.params.userName;

        this.props.getUserData(userName);
        axios.get(`/user/${userName}`)
        .then(res =>{
            this.setState({
                profile: res.data.user  
            })
        })
        .catch(err=> console.log(err))
    }
    render() { 
        const { writeups, loading } = this.props.data;
        const { writeUpIdParam } = this.state;
        const writeUpsMarkup = loading ? (
            <WriteUpSkelton />
        ) : writeups === null ? (
            <p>No writeups from this user</p>
        ) : !writeUpIdParam ? (
            writeups.map(writeup => <WriteUp key={writeup.writeUpId} writeup={writeup} />)
        ) : ( 
            writeups.map(writeup => {
                if(writeup.writeUpId !== writeUpIdParam)
                return <WriteUp key={writeup.writeUpId} writeup={writeup} />

                else return <WriteUp key={writeup.writeUpId} writeup={writeup}/>
            })  
        )

        return (
            <Grid container spacing={2}>
                <Grid item lg={4} md={6} xs={12}>
                    {this.state.profile === null ? (
                        <ProfileSkelton />
                    ):(
                        <StaticProfile profile={this.state.profile}/>
                    )}
                </Grid>
                <Grid item lg={8} md={6} xs={12}>
                    {writeUpsMarkup}
                </Grid>
            </Grid>
        );
    }
}

User.propTypes ={
    getUserData: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
}

const mapStateToProps = state =>({
    data: state.data
})
 
export default connect(mapStateToProps, {getUserData})(User);