import React, { Component, Fragment} from 'react';
import SideBarItem from "./sidebaritem";

// Material-UI
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';

  const styles = (theme) =>({
    
})

class SideBar extends Component {
    state ={
        addingNote: false,
        head: null
    }

    newNote= () => {
        this.setState({ 
            addingNote: !this.state.addingNote,
            head: null
        })
    }

    updateTitle =(txt) =>{
        this.setState({
            head: txt
        })
    }

    newNoteSubmit = () =>{
        this.props.newNote(this.state.head);
        this.setState({
            head: null,
            addingNote: false
        })
    }

    selectNote = (note, index) => this.props.selectNote(note, index);

    deleteNote = (note) => this.props.deleteNote(note);

    render() { 
        const { classes, notes, selectedNoteId } = this.props
        
        if(notes){
            return (
                <div className={classes.sidebarContainer}>
                    <Button
                    onClick={this.newNote}
                    variant="contained" color="primary"
                    >
                        {this.state.addingNote ? "Cancel" : "New Note"}
                    </Button>
                    {this.state.addingNote ? (
                        <Fragment>
                            <input type="text"
                            placeholder="Enter note title"
                            onKeyUp={(e)=> this.updateTitle(e.target.value)}
                            className={classes.newNoteInput}
                        />
                        <Button
                            onClick={this.newNoteSubmit}
                            variant="contained" color="secondary"
                        >Submit
                        </Button>
                        </Fragment>
                    ) : null
                    }
                    <List>
                         {
                             notes.map((note) =>{
                                 return(
                                     <div key={note.noteId}>
                                         <SideBarItem 
                                         note={note}
                                         id={note.noteId}
                                         selectedNoteId={selectedNoteId}
                                         selectNote={this.selectNote}
                                         deleteNote={this.deleteNote}
                                         />
                                     </div>
                                 )
                             })
                         }
                    </List>
                </div>
            );
        } else {
            return(
                <div>
                
            </div>
            )
        }
    }
}
 
export default withStyles(styles)(SideBar);