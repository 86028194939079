import React from 'react';
import './App.css';
import themeFile from './utils/theme';
import jwtDecode from "jwt-decode";
import axios from "axios";

//Material-UI
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

//Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { SET_AUTHENTICATED } from "./redux/types"
import { logoutUser, getUserData } from "./redux/actions/userActions"
import Main from './components/main';

const theme = createMuiTheme(themeFile);

axios.defaults.baseURL = 'https://us-central1-scrwlddotcom.cloudfunctions.net/api';

const token= localStorage.FBIdToken;
if(token){
  const decodedToken = jwtDecode(token);
  if(decodedToken.exp * 1000 < Date.now()){ 
    store.dispatch(logoutUser());
    window.location.href = '/login';

  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
  }
}

function App(props) {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Main />
        </Provider>
      </MuiThemeProvider>
    );  
}
 
export default App;
