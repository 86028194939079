import React, { Component, Fragment} from 'react';
import PropTypes from "prop-types";

//Material-UI
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';    
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import MenuItem from '@material-ui/core/MenuItem';

//Redux
import { connect } from 'react-redux';
import { editUserDetails } from "../../redux/actions/userActions";

const styles = (theme) =>({
    ...theme.spreadThis,
    editButton: {
        float: 'right'
    }
})

class EditDetails extends Component {
    state={
        bio: '',
        website: '',
        location: '',
        open: false
    }

    componentDidMount(){
        const{ credentials } = this.props
        this.mapUserDetailsToState(credentials);
    }

    handleOpen = () =>{
        this.setState({ open: true});
        this.mapUserDetailsToState(this.props.credentials);
    }

    handleClose = () => {
        this.setState({ open: false})
    }

    mapUserDetailsToState = (credentials) =>{
        this.setState({
            bio: credentials.bio ? credentials.bio : '',
            website: credentials.website ? credentials.website : '',
            location: credentials.location ? credentials.location : '',
        })
    }

    handleChange = (event) =>{
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleSubmit = () =>{
        const userDetails = {
            bio: this.state.bio,
            website: this.state.website,
            location: this.state.location,
        }
        this.props.editUserDetails(userDetails);
        this.handleClose();
    }

    render() { 
        const { classes } = this.props;
        return (
            <Fragment>
                <MenuItem onClick={this.handleOpen}>
                    Edit Your Profile Details
                </MenuItem>
                <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                fullWidth
                maxWidth="sm">
                    <DialogTitle>Edit Your Details</DialogTitle>
                    <DialogContent>
                        <form>
                            <TextField 
                                name="bio" 
                                type="text" 
                                lable="Bio" 
                                multiline 
                                rows="3"
                                placeholder="A short bio about yourself"
                                className={classes.textField}
                                value={this.state.bio}
                                onChange={this.handleChange}
                                fullWidth
                                variant="filled"
                                />
                                <TextField 
                                name="website" 
                                type="text" 
                                lable="Website" 
                                placeholder="Your personal/professional website"
                                className={classes.textField}
                                value={this.state.website}
                                onChange={this.handleChange}
                                fullWidth
                                variant="filled"
                                />
                                <TextField 
                                name="location" 
                                type="text" 
                                lable="Location" 
                                placeholder="Where you live"
                                className={classes.textField}
                                value={this.state.location}
                                onChange={this.handleChange}
                                fullWidth
                                variant="filled"
                                />
                            
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

EditDetails.propTypes = {
    editUserDetails: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) =>({
    credentials: state.user.credentials
})

const mapActionsToProps = {
    editUserDetails
}
 
export default connect(mapStateToProps, mapActionsToProps )(withStyles(styles)(EditDetails));