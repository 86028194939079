import React from 'react';
import EditDetails from "./EditDetails";

//Material-UI
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from "@material-ui/core/Tooltip" 
import IconButton from "@material-ui/core/IconButton"
import Menu from '@material-ui/core/Menu';



const ProfileOptionsButton = ({ btnClassName, handlePictureClick }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    return (
        <div>
        <Tooltip>
            <IconButton 
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={btnClassName}
                > 
                <MoreVertIcon color="primary"/>
            </IconButton>
        </Tooltip>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                >
                    
                    <MenuItem onClick={handlePictureClick}>
                        Upload New Profile Picture
                    </MenuItem>
                    <EditDetails />
            </Menu>
      </div>
    );
}
 
export default ProfileOptionsButton;