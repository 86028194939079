import React, { Component, Fragment } from 'react';
import SideBar from "../components/notes/sidebar"
import Editor from "../components/notes/editor"
import PropTypes from "prop-types"
import Link from "react-router-dom/Link";

//Material-UI
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Redux
import { connect } from 'react-redux';

const styles = (theme) =>({
    paper: {
        padding: 20
      },
      buttons: {
        textAlign: 'center',
        '& a': {
          margin: '20px 10px'
        }
  }
})

class Chat extends Component {
    state = {  }
    render() { 
        return (
            <div>
                Coming Soon!
            </div>
        );
    }
}

const mapStateToProps = (state) =>({
    user: state.user
})
 
export default connect(mapStateToProps)(withStyles(styles)(Chat));