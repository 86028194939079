import React, { Fragment, Component } from 'react';
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Parse from "html-react-parser"
import SingleDraftSkelton from "../utils/skeltons/SingleDraftSkelton"

//Material-UI
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

//Redux
import { connect } from "react-redux";
import { getDraft, clearErrors } from "../redux/actions/dataActions";

const styles = (theme) => ({
    ...theme.spreadThis,
    invisibleSeparator: {
        border: 'none',
        margin: 4
    },
    visibleSeparator: {
        margin: 4
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    styleProgress: {
        textAlign: 'center',
        marginTop: 50,
        marginBottom: 50
    },
    genreStyle:{
        color: '#808080',
    }

})

class DraftDialog extends Component {
    state = {  }

    componentDidMount(){
        const { match: { params } } = this.props;
       const draftIdParam = params.draftId;

       this.props.getDraft(draftIdParam);
   }

    render() { 
        const { classes, 
            draft: { body, head, createdAt, genre}, 
            UI: { loading },
        } = this.props;

        const displayBody = (body) =>{
            if(body) return Parse(body)
        }
        const displayGenre = (genre) =>{
            if(genre) return genre.toUpperCase()
        }
    
        const dialogMarkup = loading ? (
                <SingleDraftSkelton />
            ) : (
                <Fragment>
                        <Card>
                            <CardHeader
                            title={<Typography variant="p" className={classes.genreStyle}>{displayGenre(genre)}</Typography>}
                            />
                            <CardContent>
                                <Typography variant="h4">
                                    {head}
                                </Typography>
                                <Typography color="textSecondary">
                                    {dayjs(createdAt).format('MMM YYYY')}
                                </Typography>
                                {displayBody(body)}
                            </CardContent>
                        </Card>
                </Fragment>
            )
        return (
            <Grid container >
                <Grid item lg={2} md={1} xs={12}>
                    
                    </Grid>
                <Grid lg={8} md={10} xs={12}>
                    {dialogMarkup}
                </Grid>
                <Grid item lg={2} md={1} xs={12}>
                    
                </Grid>
            </Grid>
        );
    }
}

DraftDialog.propTypes = {
    clearErrors: PropTypes.func.isRequired,
    getDraft: PropTypes.func.isRequired,
    UI: PropTypes.object.isRequired,
    draft: PropTypes.object.isRequired,
}
 
const mapStateToProps = state => ({
    UI: state.UI,
    draft: state.data.draft,
})

const mapActionsToProps = {
    getDraft,
    clearErrors
}
export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(DraftDialog));