import React, { Component, Fragment } from 'react';
import DraftUI from "../components/drafts/DraftUI";
import PropTypes from "prop-types";
import Link from "react-router-dom/Link";
import DraftSkelton from '../utils/skeltons/DraftSkelton';

//Material-UI
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";

// Redux
import { connect } from 'react-redux';
import { getDrafts } from './../redux/actions/dataActions';



const styles = {
    progress: {
        marginLeft: "40%",
        marginTop: "40%"
    },
    paper: {
        padding: 20
      },
      buttons: {
        textAlign: 'center',
        '& a': {
          margin: '20px 10px'
        }
  }
}

class Drafts extends Component {
    componentDidMount(){
        this.props.getDrafts();
    };
    render() { 
        const { classes,
            user: { authenticated }
        } = this.props;
        const { drafts, loading } = this.props.data;
        let recentDrafts = authenticated ? ( 
            !loading ? (
                drafts ? (
                    drafts.map(draft =><DraftUI key={draft.draftId} draft={draft} />)
                    ) : (
                        <Paper className={classes.paper}>
                            <Typography variant="body2" align="center">
                                You have not saved any draft yet!
                            </Typography>
                        </Paper>
            )
        ):(
            <DraftSkelton />
        )) : (
            <Fragment>
                <Grid item lg={4} xs={12}></Grid>
                <Grid item lg={4} xs={12}>
                    <Paper className={classes.paper}>
                        <Typography variant="body2" align="center">
                            No draft found, please Login/SignUp
                        </Typography>
                        <div className={classes.buttons}>
                            <Button variant="contained" color="primary" component={Link} to="/login">Login</Button>
                            <Button variant="contained" color="secondary" component={Link} to="/signup">Signup</Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item lg={4} xs={12}></Grid>
            </Fragment>
        );
        return (
            <Grid container spacing={2}>
                    {recentDrafts}
            </Grid>
        );
    }
}
 
Drafts.propTypes = {
    getDrafts: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
}


const mapStateToProps = (state) => ({
    user: state.user,
    data: state.data
})
 
export default connect(mapStateToProps, {getDrafts})(withStyles(styles)(Drafts));