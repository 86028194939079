import React, { Component, Fragment } from 'react';
import dayjs from "dayjs";
import { Link } from "react-router-dom";

// Material UI
import withStyles from "@material-ui/core/styles/withStyles"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

//Redux
import { connect } from "react-redux";
import { deleteComment } from "../../redux/actions/dataActions"

const styles = theme => ({
    ...theme.spreadThis,
    commentCard: {
        margin: 20
    },
    deleteIcon: {
        float: 'right',
        cursor: 'pointer'
    }
})

class CommentUI extends Component {
    state = { 
        open: false
    }

    onOpen = () =>{
        this.setState({ open: true})
    }

    onClose = () =>{
        this.setState({ open: false})
    }

    onDeleteComment = () =>{
        this.props.deleteComment(this.props.writeUpId, this.props.comment.commentId)
        this.setState({ open: false})
    }

    render() { 
        const { classes, comment: {body, createdAt, userImage, userName}, user: { authenticated, credentials  } } = this.props

        const deleteComment = authenticated && userName === credentials.userName ? (
            <DeleteIcon  className={classes.deleteIcon} onClick={this.onOpen} />
        ) : null;
        return (
            <Fragment>
                <Card className={classes.commentCard} key={createdAt}>
                    <CardHeader
                        avatar={<Avatar src={userImage}></Avatar>}
                        title={<Typography component={Link} to={`/user/${userName}`} color="primary">{userName}</Typography>}
                        subheader={dayjs(createdAt).format('h:mm a, MMMM DD YYYY')}
                    />
                    <CardContent>
                        <Typography variant="body1">{body} {deleteComment}</Typography>
                    </CardContent>
                </Card>
                <Dialog
                    open={this.state.open}
                    onClose={this.onClose}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>
                        Are you sure you want to delete this comment?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={this.onClose} color="primary">No</Button>
                        <Button onClick={this.onDeleteComment} color="secondary" >Yes</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) =>({
    user: state.user
})

const mapActionsToProps = {
    deleteComment
}
 
export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(CommentUI));