import React, { Component} from 'react';
import ReactQuill from "react-quill"
import debounce from "../../utils/Helper"
import 'react-quill/dist/quill.snow.css';

// Material-UI
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) =>({
    editorContainer: {
        height: '100%'
    },
    reactQuill: {
        height: '458px'
    }
})

class Editor extends Component {
    constructor(){
        super();
        this.state = {
            body: '',
            head: '',
            id: '',
            createdAt: ''
        }
    }

    componentDidMount = () =>{
        this.setState({
            body: this.props.selectedNote.body,
            head: this.props.selectedNote.head,
            id: this.props.selectedNoteId
        }) 
    }

    componentDidUpdate = () =>{
        if(this.props.selectedNoteId !== this.state.id){
            this.setState({
                body: this.props.selectedNote.body,
                head: this.props.selectedNote.head,
                id: this.props.selectedNoteId
            }) 
        }
    }

    updateBody = async(val) =>{
        await this.setState({ body: val});
        this.update();
    }

    update = debounce(() => {
        this.props.noteUpdate(this.state.id, {
            head: this.state.head,
            body: this.state.body,
            userName: this.props.userName
        })
    }, 1500)


    render() { 
        const {classes} = this.props
        return (
            <div className={classes.editorContainer}>
                <ReactQuill
                value={this.state.body}
                onChange={this.updateBody}
                className={classes.reactQuill}
                >
                </ReactQuill>
            </div>
        );
    }
}
 
export default withStyles(styles)(Editor);