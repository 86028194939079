import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

//Material-UI
import withStyles from '@material-ui/core/styles/withStyles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CalendarToday   from '@material-ui/icons/CalendarToday';
import LinkIcon from "@material-ui/icons/Link";
import LocationOn from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import MuiLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const styles = (theme) => ({
    paper: {
        padding: 20,
        height: 'auto'
      },
      profile: {
        '& .image-wrapper': {
          textAlign: 'center',
          position: 'relative',
        },
        '& .profile-image': {
          width: 150,
          height: 150,
          objectFit: 'cover',
          maxWidth: '100%',
          borderRadius: '50%'
        },
        '& .profile-details': {
          textAlign: 'left',
          '& span, svg': {
            verticalAlign: 'middle'
          },
          '& a': {
            color: theme.palette.primary.main
          }
        },
        '& hr': {
          border: 'none',
          margin: '0 0 10px 0'
        },
      },
      profileIcon: {
        position: 'relative',
        right: '10px',
        top: '5px',
    }
});

const StaticProfile = (props) => {
    const { classes, profile: { userName, createdAt, imageUrl, bio, website, location }} = props;

    return (
                     <Card>
                        <CardHeader 
                        avatar={<PersonIcon color="primary"/>}
                        title={<MuiLink component={Link} to={`/users/${userName}`} color="primary" variant="h5">
                                {userName}
                              </MuiLink>}
                              />
                        <CardContent>
                      <Grid container>
                        <Grid item sm={6} xs={12}>
                        <div className={classes.profile}>
                          <div className="image-wrapper">
                            <img src={imageUrl} alt="profile" className="profile-image"/>
                          </div>
                        </div>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                        <div className={classes.profile}>
                            <div className="profile-details">
                        {bio && <Typography variant="body2">{bio}</Typography>}
                        <hr />
                        {location && (
                            <Fragment>
                                <LocationOn color="primary" className={classes.profileIcon}/>
                                <span>{location}</span>
                                <hr />
                            </Fragment>
                        )}
                        {website && (
                            <Fragment>
                                <LinkIcon color="primary" className={classes.profileIcon}/>
                                <a href={website} target="_blank" rel="noopener noreferrer">{website}</a>
                                <hr />
                            </Fragment>
                        )}
                        <CalendarToday color='primary' className={classes.profileIcon}/>
                            <span>scrawling since {dayjs(createdAt).format('MMM YYYY')}</span>
                        </div>
                        </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                    </Card>
    );
}

StaticProfile.propsTypes={
    profile: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
}
 
export default withStyles(styles)(StaticProfile);