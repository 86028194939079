import React, { Fragment, Component } from 'react';
import PropTypes from "prop-types";
import EditButton from "../components/EditButton"
import dayjs from "dayjs";
import Comments from "../components/writeup/Comments"
import { Link } from "react-router-dom"
import LikeButton from "../components/likeButton"
import CommentForm from "../components/writeup/CommentForm"
import Parse from "html-react-parser"
import SingleWriteupSkelton from "../utils/skeltons/SingleWriteupSkelton"

//Material-UI
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

//Redux
import { connect } from "react-redux";
import { getWriteup, clearErrors } from "../redux/actions/dataActions";

const styles = (theme) => ({
    ...theme.spreadThis,
    invisibleSeparator: {
        border: 'none',
        margin: 4
    },
    visibleSeparator: {
        margin: 4
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    styleProgress: {
        textAlign: 'center',
        marginTop: 50,
        marginBottom: 50
    },
    genreStyle:{
        margin: '10px 0 10px 0',
        color: '#808080',
    }

})

class WriteupDialog extends Component {
    state = {  }

    componentDidMount(){
        const { match: { params } } = this.props;
       const writeUpIdParam = params.writeUpId;

       this.props.getWriteup(writeUpIdParam);
   }

    render() { 
        const { classes, 
            writeup: { writeUpId, body, head, comments, createdAt, likeCount, commentCount, userImage, userName, genre }, 
            UI: { loading },
        } = this.props;

            const displayBody = (body) =>{
                if(body) return Parse(body)
            }
            const displayGenre = (genre) =>{
                if(genre) return genre.toUpperCase()
            }
    
        const dialogMarkup = loading ? (
                <SingleWriteupSkelton />
            ) : (
                <Fragment>
                        <Card>
                            <CardHeader
                            avatar={<Avatar src={userImage} />}
                            title={<Typography component={Link} to={`/user/${userName}`} color="primary">{userName}</Typography>}
                            subheader={dayjs(createdAt).format('h:mm a, MMMM DD YYYY')}
                            />
                            <CardContent>
                                <Typography variant="h4">
                                    {head}
                                </Typography>
                                <Typography variant="body1" className={classes.genreStyle}>{displayGenre(genre)}</Typography>
                                {displayBody(body)}
                            </CardContent>
                            <CardActions>
                                <LikeButton writeUpId={writeUpId} />
                                <span>{likeCount} likes</span>
                                <EditButton tip="comment" tipPlacement="top">
                                    <ChatBubbleOutlineIcon color="primary"/>
                                </EditButton>
                                <span>{commentCount} comments</span>
                            </CardActions>
                        </Card>
                         <CommentForm writeUpId={writeUpId} />
                         <Comments comments={comments} writeUpId={writeUpId}/>
                </Fragment>
            )
        return (
            <Grid container >
                <Grid item lg={2} md={1}xs={12}>
                    
                    </Grid>
                <Grid item lg={8} md={10} xs={12}>
                    {dialogMarkup}
                </Grid>
                <Grid item lg={2} md={1} xs={12}>
                    
                </Grid>
            </Grid>
        );
    }
}

WriteupDialog.propTypes = {
    clearErrors: PropTypes.func.isRequired,
    getWriteup: PropTypes.func.isRequired,
    UI: PropTypes.object.isRequired,
    writeup: PropTypes.object.isRequired,
}
 
const mapStateToProps = state => ({
    UI: state.UI,
    writeup: state.data.writeup,
})

const mapActionsToProps = {
    getWriteup,
    clearErrors
}
export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(WriteupDialog));