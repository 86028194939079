import React, { Fragment } from 'react';
import NoImg from "../../images/blank_profile.png"
import PropTypes from 'prop-types';

//Material-Ui
import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';


const styles= (theme) => ({
    card: {
        marginBottom: 20,
    },
    cardContent: {
        width: '100%'
    },
    handle:{
        width: 60,
        height: 15,
        backgroundColor: '#303841',
        marginBottom: '10px'
    },
    date: {
        width: 80,
        height: 15,
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
    },
    fullLine: {
        width: '80%',
        height: 15,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        marginBottom: '10px'
    },
    halfLine: {
        width: '40%',
        height: 15,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        marginBottom: '10px'
    },

});

const SingleWriteupSkelton = (props) => {
    const { classes } = props

    const writeup = Array.from({ length: 1}).map((item, index) =>(
        <Card className={classes.card} key={index}>
            <CardHeader 
                avatar={<Avatar src={NoImg} />}
                title={<div className={classes.handle} />} 
                subheader={<div className={classes.date} />}
            />
            <CardContent className={classes.cardContent}>
                <div className={classes.halfLine} />
                <div className={classes.fullLine} />
            </CardContent>
            <CardActions>
                <div className={classes.halfLine} />
            </CardActions>
        </Card>
    ));

    const comments = Array.from({ length: 5}).map((item, index) =>(
        <Card className={classes.card} key={index}>
            <CardHeader 
                avatar={<Avatar src={NoImg} />}
                subheader={<div className={classes.halfLine} />}
            />
        </Card>
    ))
    return (
        <Fragment>
            {writeup}
            {comments}
        </Fragment>
    );
}

SingleWriteupSkelton.propTypes = {
    classes: PropTypes.object.isRequired
}
 
export default withStyles(styles)(SingleWriteupSkelton);  