import React from 'react';
import Tooltip from "@material-ui/core/Tooltip" 
import IconButton from "@material-ui/core/IconButton"

const EditButton = ({ children, handleClick, tip, tipPlacement, btnClassName, tipClassName}) => {
    return (
        <Tooltip title={tip} className={tipClassName} placement={tipPlacement}>
            <IconButton onClick={handleClick} className={btnClassName}> 
                {children}
            </IconButton>
        </Tooltip>
    );
}
 
export default EditButton;