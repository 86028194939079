import React from 'react';

//Material-UI
import { withStyles } from "@material-ui/core/styles";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Paper from '@material-ui/core/Paper'

//components 
import Navbar from "./Navbar"

const styles = (theme) => ({
    root: {
        display: 'flex',
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1
      },
      // necessary for content to be below app bar
      toolbar: theme.mixins.toolbar,
      drawerPaper: {
        width: 300,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
})

const Main = ({ children, classes}) => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (open) => () => {
      setOpen(open);
    };

  const drawer = (
    <Paper className={classes.drawerPaper} />
  );

    return (
        <div className={classes.root}>
          <Navbar toggleDrawer={toggleDrawer} appBar={classes.appBar}/>
          <div className={classes.toolbar} />
          <SwipeableDrawer
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {drawer}
          </SwipeableDrawer>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {children}
          </main>
        </div>
    );
}
 
export default withStyles(styles)(Main);