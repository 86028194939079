import React, { Component } from 'react';
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import PropTypes from 'prop-types';
import EditButton from "../EditButton";
import PostOptionsButton from "./PostOptionsButton";
import LikeButton from "../likeButton"
import CommentForm from './CommentForm';

//Material-UI
import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

//Redux
import { connect } from "react-redux";


const styles = ({
    card: {
        marginBottom: 20,
    },
    genreStyle:{
        marginTop: 10,
        color: '#808080',
    }
  });

class WriteUp extends Component {
    state= {
        open: false
    }

    handleOpen = () => {
        this.setState({open: true})
      };
    
    handleClose = () => {
        this.setState({open: false})
      };

    render() { 
        dayjs.extend(relativeTime)
        const { classes,
            writeup  : {head, createdAt, userImage, userName, writeUpId, likeCount, commentCount, genre},
            user: { authenticated, credentials  }
        } = this.props;

        const optionsButton = authenticated && userName === credentials.userName ? (
            <PostOptionsButton writeUpId={writeUpId}/>
        ) : null

        const displayGenre = (genre) =>{
            if(genre) return genre.toUpperCase()
        }
        const commentOptionDisplay = !authenticated ? (
            <EditButton tip="like" tipPlacement="top">
                <Link to="/login" >
                    <ChatBubbleOutlineIcon color="primary"/>
                </Link>
            </EditButton>
        ) : (
            <EditButton tip="comment" tipPlacement="top" handleClick={this.handleOpen}>
                <ChatBubbleOutlineIcon color="primary"/>
            </EditButton>
        );

        return (  
            <div ref={this.props.ref}>
                <Card className={classes.card}>
                    <CardHeader 
                        avatar={<Avatar src={userImage} />}
                        action={
                            optionsButton
                            }
                        title={<Typography component={Link} to={`/user/${userName}`} color="primary">{userName}</Typography>} 
                        subheader={dayjs(createdAt).fromNow()}
                    />
                    <CardActionArea component={Link} to={`/user/${userName}/writeup/${writeUpId}`}>
                        <CardContent>
                            <Typography variant="h4">{head}</Typography>
                            <Typography variant="body1" className={classes.genreStyle}>{displayGenre(genre)}</Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <LikeButton writeUpId={writeUpId} />
                        <span>{likeCount} likes</span>
                        {commentOptionDisplay}
                        <span>{commentCount} comments</span>
                    </CardActions>
                </Card>
                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <DialogContent>
                        <CommentForm writeUpId={writeUpId} onClose={this.handleClose}/>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

WriteUp.propTypes = {
    user: PropTypes.object.isRequired,
    writeup: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    openDialog: PropTypes.bool
}

const mapStateToProps = (state) =>({
    user: state.user
})

 
export default connect(mapStateToProps)(withStyles(styles)(WriteUp));
