import { SET_WRITEUPS, SET_WRITEUP, LOADING_DATA, LIKE_WRITEUP, UNLIKE_WRITEUP, SET_DRAFTS, DELETE_WRITEUP, POST_WRITEUP, LOADING_UI, SET_ERRORS, CLEAR_ERRORS, POST_DRAFT, STOP_LOADING_UI, SUBMIT_COMMENT, DELETE_DRAFT, SET_DRAFT, MOVE_DRAFT, DELETE_COMMENT, SET_NOTE, POST_NOTE, DELETE_NOTE, SET_NEWWRITEUPS} from "../types"
import axios from "axios";

//Get all writeups
export const getWriteups = () => (dispatch) => {
    dispatch({ type: LOADING_DATA})
    axios.get('/writeups', { params: { page: 1 } })
    .then(res => {
        dispatch({
            type: SET_WRITEUPS,
            payload: res.data
        })
    })
    .catch(err => {
        dispatch({
            type: SET_WRITEUPS,
            payload: []
        })
    })
}

export const getMoreWriteups = (pageNumber) => (dispatch) => {
    axios.get('/writeups', { params: { page: pageNumber } })
    .then(res => {
        dispatch({
            type: SET_NEWWRITEUPS,
            payload: res.data
        })
    })
    .catch(err => {
        dispatch({
            type: SET_NEWWRITEUPS,
            payload: []
        })
    })
}

export const getSortedWriteups = (genre) => (dispatch) => {
    dispatch({ type: LOADING_DATA})
    axios.get(`/writeups/${genre}`)
    .then(res => {
        dispatch({
            type: SET_WRITEUPS,
            payload: res.data
        })
    })
    .catch(err => {
        dispatch({
            type: SET_WRITEUPS,
            payload: []
        })
    })
}

//Get drafts
export const getDrafts = () => (dispatch) => {
    dispatch({ type: LOADING_DATA})
    axios.get('/drafts')
    .then(res => {
        dispatch({
            type: SET_DRAFTS,
            payload: res.data
        })
    })
    .catch(err => {
        dispatch({
            type: SET_DRAFTS,
            payload: []
        })
    })
}

//Get a Particular WriteUp
export const getWriteup = (writeUpId) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.get(`/writeup/${writeUpId}`)
    .then(res => {
        dispatch({
            type: SET_WRITEUP,
            payload: res.data
        });
        dispatch({ type: STOP_LOADING_UI })
    })
    .catch(err => console.log(err));
}

// Get a Particular Draft
export const getDraft = (draftId) => (dispatch) => {
    dispatch({ type: LOADING_UI})
    axios.get(`/drafts/${draftId}`)
    .then(res => {
        dispatch({
            type: SET_DRAFT,
            payload: res.data
        })
        dispatch({ type: STOP_LOADING_UI })
    })
    .catch(err => console.log(err))
}

//Post WriteUp
export const postWriteup = (newWriteup) => (dispatch) =>{
    dispatch({ type: LOADING_UI });
    axios.post('/writeup', newWriteup)
    .then(res => {
        dispatch({
            type: POST_WRITEUP,
            payload: res.data
        })
        dispatch(clearErrors());
    })
    .catch(err =>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
        })
    })
}

//Post Draft
export const postDraft = (newDraft) => (dispatch) =>{
    dispatch({ type: LOADING_UI });
    axios.post('/drafts', newDraft)
    .then(res => {
        dispatch({
            type: POST_DRAFT,
            payload: res.data
        })
        dispatch(clearErrors());
    })
    .catch(err =>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
        })
    })
}

// Post Darft into Posts
export const moveDraft = (draft, draftId) => (dispatch) =>{
    dispatch({ type: LOADING_UI });
    axios.post(`/drafts/${draftId}`, draft)
    .then(res => {
        dispatch({
            type: MOVE_DRAFT,
            payload: res.data
        })
        dispatch(deleteDraft(draftId));
        dispatch(getWriteups());
    })
    .catch(err =>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
        })
    })
}

//edit Writeup
export const editWriteup = (writeup, writeUpId) => (dispatch) =>{
    dispatch({ type: LOADING_UI });
    axios.put(`/writeup/${writeUpId}`, writeup)
    .then(res => {
        dispatch(getWriteups());
        dispatch(clearErrors());
    })
    .catch(err =>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
        })
    })
}

// edit Draft
export const editDraft = (draft, draftId) => (dispatch) =>{
    dispatch({ type: LOADING_UI });
    axios.put(`/drafts/${draftId}`, draft)
    .then(res => {
        dispatch(getDrafts());
        dispatch(clearErrors());
    })
    .catch(err =>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
        })
    })
}


//Like a writeup
export const likeWriteup = (writeUpId) => (dispatch) => {
    axios.get(`/writeup/${writeUpId}/like`)
    .then(res =>{
        dispatch({
            type: LIKE_WRITEUP,
            payload: res.data
        })
    })
    .catch(err => console.log(err));
}

//Unlike a writeup
export const unlikeWriteup = (writeUpId) => (dispatch) => {
    axios.get(`/writeup/${writeUpId}/unlike`)
    .then(res =>{
        dispatch({
            type: UNLIKE_WRITEUP,
            payload: res.data
        })
    })
    .catch(err => console.log(err));
}

//Post a comment
export const submitComment = (writeUpId, commentData) => (dispatch) => {
    axios.post(`/writeup/${writeUpId}/comment`, commentData)
    .then(res =>{
        dispatch({
            type: SUBMIT_COMMENT,
            payload: res.data
        })
        dispatch(clearErrors());
    })
    .catch(err => {
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
        })
    })
}

// Delete a Comment
export const deleteComment = (writeUpId, commentId) => (dispatch) => {
    axios.delete(`/writeup/${writeUpId}/comments/${commentId}`)
    .then((res)=> {
        dispatch({ 
            type: DELETE_COMMENT,
            payload: res.data
        })
        dispatch(getWriteup(writeUpId));
    })
    .catch(err=> console.log(err));
}


// delete a writeup
export const deleteWriteup = (writeUpId) => (dispatch) => {
    axios.delete(`/writeup/${writeUpId}`)
    .then((res)=> {
        dispatch({ 
            type: DELETE_WRITEUP,
            payload: res.data
        })
        dispatch(getWriteups());
    })
    .catch(err=> console.log(err));
}

// delete a draft
export const deleteDraft = (draftId) => (dispatch) => {
    axios.delete(`/drafts/${draftId}`)
    .then((res)=> {
        dispatch({ 
            type: DELETE_DRAFT,
            payload: res.data
        })
        dispatch(getDrafts());
    })
    .catch(err=> console.log(err));
}

// Getting User Data
export const getUserData =(userName) => (dispatch) => {
    dispatch({ type: LOADING_DATA})
    axios.get(`/user/${userName}`)
    .then(res => {
        dispatch({
            type: SET_WRITEUPS,
            payload: res.data.writeups
        })
    })
    .catch(() =>{
        dispatch({
            type: SET_WRITEUPS,
            payload: null
        })
    })
}

//Get Note
export const getNote = () => (dispatch) => {
    dispatch({ type: LOADING_DATA})
    axios.get('/notes')
    .then(res => {
        dispatch({
            type: SET_NOTE,
            payload: res.data
        })
    })
    .catch(err => {
        dispatch({
            type: SET_NOTE,
            payload: []
        })
    })
}

// Post a New Note
export const postNote = (newNote) => (dispatch) =>{
    dispatch({ type: LOADING_UI });
    axios.post('/notes', newNote)
    .then(res => {
        dispatch({
            type: POST_NOTE,
            payload: res.data
        })
        dispatch(clearErrors());
    })
    .catch(err =>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
        })
    })
}

//Update Note
export const updateNote = (id, note) => (dispatch) =>{
    dispatch({ type: LOADING_UI });
    axios.put(`/notes/${id}`, note)
    .then(res => {
        dispatch(getNote());
        dispatch(clearErrors());
    })
    .catch(err =>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
        })
    })
}

export const deleteNote = (noteId) => (dispatch) => {
    axios.delete(`/notes/${noteId}`)
    .then((res)=> {
        dispatch({ 
            type: DELETE_NOTE,
            payload: res.data
        })
        dispatch(getNote());
    })
    .catch(err=> console.log(err));
}

export const clearErrors = () => (dispatch) =>{
    dispatch({ type: CLEAR_ERRORS})
}