import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import PropTypes from 'prop-types';
import relativeTime from 'dayjs/plugin/relativeTime';

//Material-UI
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography  from '@material-ui/core/Typography';

//Redux
import { connect } from "react-redux";
import { markNotificationsRead } from "../redux/actions/userActions"


class Notifications extends Component {
    state = {  
        anchorEl: null
    }

    handleOpen =(event) =>{
        this.setState({anchorEl: event.target})
    }

    handleClose =() => {
        this.setState ({ anchorEl: null})
    }

    onMenuOpened = () =>{
        let unreadNotificationsIds = this.props.notifications
            .filter(not => !not.read)
            .map(not => not.notificationId);
        this.props.markNotificationsRead(unreadNotificationsIds);
    }

    render() { 
        const notifications = this.props.notifications;
        let notificationIcon;
        dayjs.extend(relativeTime);

        if( notifications && notifications.length > 0){
            notifications.filter(not => not.read === false).length > 0 ? (notificationIcon = (
                <Badge badgeContent={notifications.filter(not => not.read === false).length} color="secondary">
                    <NotificationsIcon />
                </Badge>
            )) : (
                notificationIcon = <NotificationsIcon />
            )
        } else {
            notificationIcon = <NotificationsIcon />
        }
        
        let notificationsMarkup =
            notifications && notifications.length > 0 ? (
                notifications.map(not => {
                    const verb = not.type === 'like' ? 'liked' : 'commented on';
                    const time = dayjs(not.createdAt).fromNow();
                    const iconColor = not.read ? 'primary' : 'secondary';
                    const icon = not.type === 'like' ? (
                        <FavoriteIcon color={iconColor} style={{marginRight: 10}} />
                    ) : (
                        <ChatBubbleOutlineIcon color={iconColor} style={{marginRight: 10}} />
                    )
                    return (
                        <MenuItem key={not.createAt} onClick={this.handleClose}>
                            {icon}
                            <Typography component={Link} color="default" variant="body1" to={`/user/${not.recipient}/writeup/${not.writeUpId}`}>
                                {not.sender} {verb} your writeup {time}
                            </Typography>
                        </MenuItem>
                    )
                })
            ) : (
                <MenuItem onClick={this.handleClose}>
                    You have no notifications yet.
                </MenuItem>
            )

        return (
            <Fragment>
                <Tooltip title="Notifications" placement="bottom">
                    <IconButton aria-owns={this.state.anchorEl ? 'simple-menu' : undefined} aria-haspopup='true' onClick={this.handleOpen}>
                        {notificationIcon}
                    </IconButton>
                </Tooltip>
                <Menu anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.handleClose} onEntered={this.onMenuOpened}>
                    {notificationsMarkup}
                </Menu>
            </Fragment>
        );
    }
}

Notifications.propTypes = {
    markNotificationsRead: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    notifications: state.user.notifications
}) 
 
export default connect(mapStateToProps, { markNotificationsRead })(Notifications);