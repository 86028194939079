import React from 'react';
import AuthRoute from "../utils/authRoute";
import { BrowserRouter as  Router, Route, Switch } from 'react-router-dom';

//Material-UI
import {withStyles} from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';

//components
import SideBar from "./layout/SideBar"; 
import Appbar from "./layout/Appbar"

//pages
import home from "../pages/home";
import drafts from "../pages/drafts";
import login from "../pages/login";
import signup from "../pages/signup";
import profile from "../pages/profile";
import user from "../pages/user"
import WriteupDialog from '../pages/WriteupDialog';
import DraftDialog from '../pages/DraftDialog';
import Notes from '../pages/notes'
import chat from '../pages/chat';

const styles = (theme) => ({
    
})

const Main = (classes) => {

    return (
      <Router>
        <SideBar>
        <Container maxWidth="xl">
          <Switch>
            <Route exact path="/" component={home}/>
            <Route exact path="/drafts" component={drafts}/>
            <Route exact path="/profile" component={profile}/>
            <AuthRoute exact path="/login" component={login} />
            <AuthRoute exact path="/signup" component={signup} />
            <Route exact path="/user/:userName" component={user}/>
            <Route exact path="/user/:userName/writeup/:writeUpId" component={WriteupDialog}/>
            <Route exact path="/user/:userName/drafts/:draftId" component={DraftDialog}/>
            <Route exact path="/user/:userName/notes" component={Notes}/>
            <Route exact path="/chat" component={chat} />
          </Switch> 
        <Appbar/>
        </Container>
      </SideBar>
      </Router>
    );
}
 
export default withStyles(styles)(Main);