import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import EditButton from "../EditButton";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//Material-UI
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//Redux
import { connect } from "react-redux";
import { getWriteup, editWriteup, clearErrors } from "../../redux/actions/dataActions";


const styles = (theme) =>({
    ...theme.spreadThis,
    submitButton:{
        position: 'relative',
        margin: '5px'
    },
    progressSpinner: {
        position: 'absolute'
    },
    closeButton: {
        color: 'white',
        left: '90%'
    },
    areaContent: {
        marginTop: '75px'
    },
    textArea: {
        width: 'auto'
    },
    fabbutton: {
        bottom: 10,
      },
    formControl: {
        minWidth: 120,
    }
  });

class EditWriteUp extends Component {
    constructor(props) {
        super(props)
        this.handleQuill = this.handleQuill.bind(this)
    }
    state = {
        open: false,
        head: '',
        body: '',
        genre: '',
        errors: {},
    }

    componentDidMount(){
        this.props.getWriteup(this.props.id);
    }

    handleQuill(value){
        this.setState({ body: value})
    }

    static getDerivedStateFromProps(nextProps){
        if (nextProps.UI.errors) {
            return ({ errors: nextProps.UI.errors });
          }
        else {
            return ({errors: {}})
        }
    }
    
    handleOpen =() =>{
        this.setState({open: true})
        this.mapWriteupToState(this.props.writeup);
    }

    handleClose =() =>{
        this.props.clearErrors();
        this.setState({open: false, errors: {}})
    }

    handleChange =(event) =>{
        this.setState({ head: event.target.value})
    }

    handleGenre =(event) =>{
        this.setState({ genre: event.target.value})
    }

    handleSubmit= (event) => {
        event.preventDefault();
    const updateWriteup ={
        head: this.state.head,
        body: this.state.body,
        genre: this.state.genre
    }
    this.props.editWriteup(updateWriteup, this.props.writeup.writeUpId);
    }

    mapWriteupToState = (writeup) =>{
        this.setState({ 
            head: writeup.head ? writeup.head : '',
            body: writeup.body ? writeup.body : '',
            genre: writeup.genre ? writeup.genre: '',
        })
    }

    render() { 
        const { errors } = this.state;
        const { classes, 
            UI: { loading },
        } = this.props;
        return (
            <Fragment>
                <MenuItem onClick={this.handleOpen} com>
                        Edit
                </MenuItem>
                <Dialog
                    open={this.state.open} 
                    onClose={this.handleClose}
                    fullScreen
                    fullWidth
                    maxWidth="sm"
                >
                    <AppBar>
                        <Toolbar>
                        <EditButton tip="close" handleClick={this.handleClose} tipClassName={classes.closeButton}>
                        <CloseIcon className={classes.closeButton}/>
                    </EditButton>
                    <DialogTitle>Post a new writeup</DialogTitle>
                        </Toolbar>
                    </AppBar>
                    
                    <DialogContent className={classes.areaContent}>
                    <FormControl className={classes.formControl} variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">Genre</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.genre}
                            onChange={this.handleGenre}
                            label="Genre"
                        >
                            <MenuItem value="quote">Quote</MenuItem>
                            <MenuItem value="musing">Musing</MenuItem>
                            <MenuItem value="microtale">Microtale</MenuItem>
                            <MenuItem value="dictionary">Dictionary</MenuItem>
                            <MenuItem value="poem">Poem</MenuItem>
                            <MenuItem value="letter">Letter</MenuItem>
                            <MenuItem value="story">Story</MenuItem>
                        </Select>
                    </FormControl>
                        <TextField
                            name="head"
                            type="text"
                            label="TITLE"
                            value={this.state.head}
                            error={errors.head ? true : false}
                            helperText={errors.head}
                            className={classes.textField}
                            onChange={this.handleChange}
                            fullWidth
                            variant="outlined"
                            />
                            <br />
                            <ReactQuill 
                                value={this.state.body}
                                onChange={this.handleQuill} 
                            />
                            {errors.body && (
                            <Typography variant="body2" className={classes.customError}>
                                {errors.body}
                            </Typography>
                            )}
                            
                            <Button variant="contained" color="primary" className={classes.submitButton} disabled={loading} onClick={this.handleSubmit}>
                                Submit
                                {loading && (<CircularProgress size={30} className={classes.progressSpinner} />)}
                            </Button>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

EditWriteUp.propTypes = ({
    editWriteup: PropTypes.func.isRequired,
    UI: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired,
    getWriteup: PropTypes.func.isRequired,
    writeup: PropTypes.object.isRequired,
})

const mapStateToProps = (state) =>({
    UI: state.UI,
    writeup: state.data.writeup,
})

const mapActionToProps = {
    editWriteup,
    clearErrors,
    getWriteup,
}
 
export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(EditWriteUp));