import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

//Redux
import { connect } from 'react-redux';
import { loginUser } from "../redux/actions/userActions"

//Material-UI
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) =>({
    ...theme.spreadThis
});

class Login extends Component {
    constructor(){
        super();
        this.state = {
            email: '',
            password: '',
            errors: {}
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.UI.errors) {
            return ({ errors: nextProps.UI.errors });
          } else {
            return ({ errors: {}});
          }
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        const userData= {
            email: this.state.email,
            password: this.state.password
        };
        this.props.loginUser(userData, this.props.history);
    }

    handleChange = (event) =>{
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    render() { 
        const { classes, UI: { loading } } = this.props;
        const { errors } = this.state;
        return (
            <Grid container>
                <Grid item sm />
                <Grid item sm>
                    <div className={classes.form}>
                    <Typography variant="h3" className={classes.pageTitle}>Login</Typography>
                    <form noValidate onSubmit={this.handleSubmit}>
                        <TextField 
                            id="email" 
                            name="email" 
                            type="email" 
                            label="Email" 
                            className={classes.textField}
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            value={this.state.email}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                        />
                        {errors.invalidEmail && (
                            <Typography variant="body2" className={classes.customError}>
                                {errors.invalidEmail}
                            </Typography>
                        )}
                        <TextField 
                            id="password" 
                            name="password" 
                            type="password" 
                            label="Password" 
                            className={classes.textField}
                            helperText={errors.password}
                            error={errors.password ? true : false}
                            value={this.state.password}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                        />
                        {errors.invalidPassword && (
                            <Typography variant="body2" className={classes.customError}>
                                {errors.invalidPassword }
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            disabled={loading}
                            >
                               Login 
                               {loading && (
                                   <CircularProgress size={30} className={classes.progress} />
                               )}
                            </Button>
                            <br />
                            <small>Don't have an account? Sign Up <Link to="/signup">here</Link></small>
                    </form>
                    </div>
                </Grid>
                <Grid item sm />
            </Grid>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
}
 
const mapStateToProps = (state) =>({
    user: state.user,
    UI: state.UI
});

const mapActionsToProps = {
    loginUser
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Login));