import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import CommentUI from "./CommentUI"

//Material-UI
import withStyles from "@material-ui/core/styles/withStyles"
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    ...theme.spreadThis
})



class Comments extends Component {
    state = { }

    render() { 
        const { classes, comments} = this.props;

        const commentDisplay = comments ? (
            comments.map((comment) => <CommentUI key={comment.createdAt} comment={comment} writeUpId={this.props.writeUpId}/>)
            ) : (
                <Paper className={classes.paper}>
                    <Typography variant="body2" align="center">
                        No comments to display!
                    </Typography>
                </Paper>
            )
        return (
            <Fragment>
                <CardContent>
                    {commentDisplay}  
                </CardContent>
            </Fragment>
            
        );
    }
}

Comments.propTypes = {
    comments: PropTypes.array.isRequired
}


 
export default withStyles(styles)(Comments);