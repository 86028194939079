import React from 'react';
import PropTypes from "prop-types";
import EditDraft from "./EditDraft"

//Material-UI
import withStyles from '@material-ui/core/styles/withStyles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";
import Menu from '@material-ui/core/Menu';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

//Redux
import {connect} from "react-redux";
import { deleteDraft } from "../../redux/actions/dataActions";

const styles = (theme) =>({
})

const DraftOptionsButton = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [state, setState] = React.useState(false);

const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOpen = () =>{
      setState(true);
  }

  const onClose = () =>{    
    setState(false);
}

const onDeleteDraft = () =>{
    props.deleteDraft(props.draftId);
    setState(false);
}

    return (
        <div>
            <IconButton 
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                > 
                <MoreVertIcon color="primary"/>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                >
                    
                    <EditDraft id={props.draftId}/>
                    <MenuItem onClick={onOpen}>
                        Delete
                    </MenuItem> 
            </Menu>
            <Dialog
            open={state}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            >
                <DialogTitle>
                    Are you sure you want to delete this Draft?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={onClose} color="primary">cancel</Button>
                    <Button onClick={onDeleteDraft} color="secondary" >submit</Button>
                </DialogActions>
            </Dialog>
      </div>
    );
}

DraftOptionsButton.propTypes = {
    deleteDraft: PropTypes.func.isRequired,
    draftId: PropTypes.string.isRequired,
}

const mapActionsToProps = {
    deleteDraft
}
 
export default connect(null, mapActionsToProps)(withStyles(styles)(DraftOptionsButton));