import React, { Component } from 'react';
import PropTypes from "prop-types";
import EditButton from "../EditButton"

//Material-UI
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InputAdornment from '@material-ui/core/InputAdornment';

//Redux
import { connect } from "react-redux";
import { submitComment } from "../../redux/actions/dataActions"

const styles = (theme) => ({
    ...theme.spreadThis,
    postComment:{
        marginLeft: 35,
        marginRight: 35,
        marginTop: 20,
    },
    button: {
        margin: 1
    }
})

class CommentForm extends Component {
    state = {
        body: '',
        errors: {}
    }

    static getDerivedStateFromProps(nextProps){
        if (nextProps.UI.errors) {
            return ({ errors: nextProps.UI.errors });
          }
        if(!nextProps.UI.errors && !nextProps.UI.loading){
            return ({
                errors: {},
            })
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value})
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.submitComment(this.props.writeUpId, { body: this.state.body })
        if(this.state.errors && Object.keys(this.state.errors).length === 0 && typeof this.props.onClose === "function"){
            this.props.onClose()
        }
    }

    render() { 
        const { classes, authenticated } = this.props;
        const errors = this.state.errors;

        const commentFormMarkup = authenticated ? (
            <Card className={classes.postComment}>
                <CardContent>
                        <TextField
                        name="body"
                        type="text"
                        label="Post a new comment"
                        errors={errors.comment ? true : false}
                        helperText={errors.comment}
                        value={this.state.body}
                        onChange={this.handleChange}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EditButton tip="comment" tipPlacement="top" handleClick={this.handleSubmit} btnClassName={classes.button}>
                                    <ArrowForwardIcon />
                                </EditButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        
                </CardContent>
            </Card>
        ) : null

        return commentFormMarkup;
    }
}

CommentForm.propTypes = {
    submitComment: PropTypes.func.isRequired,
    UI: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    writeUpId: PropTypes.string.isRequired,
    authenticated: PropTypes.bool.isRequired,
}

const mapStateToProps = state =>({
    UI: state.UI,
    authenticated: state.user.authenticated
})
 
export default connect(mapStateToProps, { submitComment })(withStyles(styles)(CommentForm));