import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import EditButton from "../EditButton";
import Notifications from '../notifications';
import AppIcon from "../../images/logoLightSmall.png"

//Material-UI
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import ChatIcon from '@material-ui/icons/Chat';
import Tooltip from "@material-ui/core/Tooltip"


//Redux
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/actions/userActions'


const styles = (theme) => ({
    icon: {
        height: '40%',
        width: 'auto',
        position: 'absolute',
        marginRight: 'auto',
        marginLeft: 55
      },
      menuIcon:{
          color: "white"
      }
})

class Navbar extends Component {
    handleLogout = () =>{
        this.props.logoutUser();
    }
    render() { 
        const {
            authenticated, classes
        }
            = this.props;
        let menuMarkUp = authenticated ? (
            <Fragment>
                <Tooltip title="Chat" placement="bottom">
                    <IconButton component={Link} to="/chat"> 
                    <ChatIcon />
                    </IconButton>
                </Tooltip>
                <Notifications />
                <EditButton tip="Logout" tipPlacement="bottom" handleClick={this.handleLogout}>
                    <ExitToAppIcon />
                </EditButton>
            </Fragment>
               
        ) : (
            <Fragment>
                <Button color="inherit" component={Link} to="/login" >Login</Button>
                <Button color="inherit" component={Link} to="/signup">Signup</Button>
            </Fragment>
        );
        return (
            <AppBar className={this.props.appBar} >
                <Toolbar>
                    <IconButton onClick={this.props.toggleDrawer(true)} className={classes.menuIcon}>
                        <MenuIcon/>
                    </IconButton>
                    <img src={AppIcon} alt="scrwld" className={classes.icon} />
                    <Toolbar className="navContainer">
                        {menuMarkUp}
                    </Toolbar> 
                </Toolbar>
            </AppBar>
        );
    }
}

Navbar.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired
}

const mapActionsToProps = {
    logoutUser
}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated
})
 
export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Navbar));