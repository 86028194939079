import React, { Component, Fragment} from 'react';
import { removeHTMLTags } from "../../utils/Helper";

// Material-UI
import withStyles from '@material-ui/core/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';

const styles = (theme) =>({
    listItem:{
        cursor: "pointer "
    },
    deleteIcon: {
        position: 'absolute',
        right: '5px',
        top: 'calc(50% - 15px)',
            '&:hover': {
                color: 'red'
            }
    }
})

class SideBarItem extends Component {
    state ={ }

    selectNote =(note) => {
        this.props.selectNote(note);
    }

    deleteNote = (note) => {
        if(window.confirm(`Are you sure you want to delete: ${note.head}`)){
            this.props.deleteNote(note);
        }
    }

    render() { 
        const { id, note, classes, selectedNoteId } = this.props
            
        return (
            <Fragment>
            <Paper>
                <ListItem
                    selected={selectedNoteId === id}
                    alignItems="flex-start"
                    className={classes.listItem}
                    onClick={()=> this.selectNote(note)}
                >
                    <div className={classes.textSection}>
                        <ListItemText primary={note.head} secondary={removeHTMLTags(note.body.substring(0, 30)) + '...'}></ListItemText>
                    </div>
                    <DeleteIcon onClick={() => this.deleteNote(note)} className={classes.deleteIcon}/>
                </ListItem>
            </Paper>
                <br />
                </Fragment>
        );
    }
}

export default withStyles(styles)(SideBarItem);