import React, { Component } from 'react';
import EditButton from "./EditButton";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

//Material-UI
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

//Redux
import { connect } from "react-redux"
import { likeWriteup, unlikeWriteup } from './../redux/actions/dataActions';
 
class LikeButton extends Component {
    likedWriteup =() => {
        if(this.props.user.likes && this.props.user.likes.find(like => like.writeUpId === this.props.writeUpId))
        return true;

        else return false;
    };

    likeWriteup = () =>{
        this.props.likeWriteup(this.props.writeUpId);
    }

    unlikeWriteup = () =>{
        this.props.unlikeWriteup(this.props.writeUpId);
    }
    render() { 
        const { user: {authenticated} }= this.props;
        const likeButton = !authenticated ? (
            <EditButton tip="like" tipPlacement="top">
                <Link to="/login" >
                    <FavoriteBorderIcon color="primary"/>
                </Link>
            </EditButton>
        ) : (
            this.likedWriteup() ? (
                <EditButton tip="unlike" tipPlacement="top" handleClick={this.unlikeWriteup}>
                    <FavoriteIcon color="primary"/>
                </EditButton>
            ) : (
                <EditButton tip="like" tipPlacement="top" handleClick={this.likeWriteup}>
                    <FavoriteBorderIcon color="primary"/>
                </EditButton>
            )
        );
        return likeButton;
    }
}

LikeButton.propTypes = {
    user: PropTypes.object.isRequired,
    likeWriteup: PropTypes.func.isRequired,
    unlikeWriteup: PropTypes.func.isRequired,
}

const mapStateToProps = (state) =>({
    user: state.user
})

const mapActionsToProps = {
    likeWriteup,
    unlikeWriteup
}

export default connect(mapStateToProps, mapActionsToProps)(LikeButton);