import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import ProfileOptionsButton from "../components/profile/ProfileOptionsButton";
import ProfileSkelton from '../utils/skeltons/ProfileSkelton';

// Material-UI
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MuiLink from "@material-ui/core/Link";
import Typography from '@material-ui/core/Typography';
import CalendarToday   from '@material-ui/icons/CalendarToday';
import LinkIcon from "@material-ui/icons/Link";
import LocationOn from '@material-ui/icons/LocationOn';
import Paper from '@material-ui/core/Paper';
import PersonIcon from '@material-ui/icons/Person';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';


// Redux
import { connect } from 'react-redux';
import { uploadImage } from '../redux/actions/userActions';

const styles = (theme) => ({
      profile: {
        '& .image-wrapper': {
          textAlign: 'center',
          position: 'relative',
        },
        '& .profile-image': {
          width: 150,
          height: 150,
          objectFit: 'cover',
          maxWidth: '100%',
          borderRadius: '50%',
        },
        '& .profile-details': {
          textAlign: 'left',
          '& a': {
            color: theme.palette.primary.main
          }
        },
        '& hr': {
          border: 'none',
          margin: '0 0 10px 0'
        },
        '& svg.button': {
          '&:hover': {
            cursor: 'pointer'
          }
        }
      },
      profileIcon: {
        position: 'relative',
        right: '10px',
        top: '5px',
    },
    paper: {
      padding: 20
    },
    buttons: {
      textAlign: 'center',
      '& a': {
        margin: '20px 10px'
      }
}
});

class Profile extends Component {

    handleImageChange = (event) => {
      const image = event.target.files[0];
      // send to server
      const formData = new FormData();
      formData.append('image', image, image.name);
      this.props.uploadImage(formData);
    }

    handleEditPicture = () =>{
      const fileInput = document.getElementById('imageInput');
      fileInput.click();
    }

    render() { 
            const { classes, 
                user: { 
                    credentials: { userName, createdAt, imageUrl, bio, website, location}, 
                    loading, 
                    authenticated}
                } 
                = this.props;

            let profileMarkup = !loading ? (authenticated ? (
                  <Card>
                        <CardHeader 
                        avatar={<PersonIcon color="primary"/>}
                        action={<ProfileOptionsButton btnClassName="button" handlePictureClick={this.handleEditPicture}/>}
                        title={<MuiLink component={Link} to={`/user/${userName}`} color="primary" variant="h5">
                        {userName}
                      </MuiLink>}
                        />
                        <CardContent>
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                    <div className={classes.profile}>
                      <div className="image-wrapper">
                            <img src={imageUrl} alt="profile" className="profile-image"/>
                            <input type="file" id="imageInput" onChange={this.handleImageChange} hidden="hidden"/>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <div className={classes.profile}>
                        <div className="profile-details">
                        {bio && <Typography variant="body2">{bio}</Typography>}
                        <hr />
                        {location && (
                            <Fragment>
                                <LocationOn color="primary" className={classes.profileIcon}/>
                                <span>{location}</span>
                                <hr />
                            </Fragment>
                        )}
                        {website && (
                            <Fragment>
                                <LinkIcon color="primary" className={classes.profileIcon}/>
                                <a href={website} target="_blank" rel="noopener noreferrer">{website}</a>
                                <hr />
                            </Fragment>
                        )}
                        <CalendarToday color='primary' className={classes.profileIcon}/>
                            <span>scrawling since {dayjs(createdAt).format('MMM YYYY')}</span>
                        </div>
                      </div>
                    </Grid>
            </Grid>
                        </CardContent>
                      </Card>
            ) : (
                <Paper className={classes.paper}>
                    <Typography variant="body2" align="center">
                        No profile found, please Login/SignUp
                    </Typography>
                    <div className={classes.buttons}>
                        <Button variant="contained" color="primary" component={Link} to="/login">Login</Button>
                        <Button variant="contained" color="secondary" component={Link} to="/signup">Signup</Button>
                    </div>
                </Paper>
            )) : <ProfileSkelton />
        return (
            <Grid container spacing={2}>
              <Grid item lg={3} md={1} xs={12}>
                        
                        </Grid>
                    <Grid item lg={6} md={10} xs={12}>
                        {profileMarkup}
                    </Grid>
                    <Grid item lg={3} md={1} xs={12}>
                        
                    </Grid>
            </Grid>
        );
    }
}

Profile.propTypes = {
    user: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    uploadImage: PropTypes.func.isRequired
}

const mapActionsToProps = {
  uploadImage
}

const mapStateToProps = (state) => ({
    user: state.user
})
 
export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Profile));