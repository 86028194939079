import React, { useState, useEffect, } from 'react';
import WriteUp from "../components/writeup/WriteUp";
import PropTypes from "prop-types"
import WriteUpSkelton from "../utils/skeltons/WriteUpSkelton";
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactPullToRefresh from 'react-pull-to-refresh';
import InfiniteScroll from 'react-infinite-scroll-component';

//Material-UI
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Hidden from '@material-ui/core/Hidden';

//Redux
import { connect } from "react-redux";
import { getWriteups, getSortedWriteups, getMoreWriteups } from './../redux/actions/dataActions';

const styles = {
    formControl: {
        minWidth: 120,
        marginBottom: 20
    },
    paperWidth: {
        height: 500,
        width: 'auto'
    }
};

const Home = (props) => {

    const [num, setNum] = useState(2);
    const [writeupsNum, setWriteupsNum] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const [totalWriteUps, setTotalWriteUps] = useState(0)

   const { data: {writeups, loading}, classes } = props;

    useEffect(() =>{
        props.getWriteups();
    }, [])

    const handleGenre =(event) =>{
        if(event.target.value === "feed"){
                props.getWriteups();
                setNum(2);
                setHasMore(true);
                setTotalWriteUps(0);
        } else {
            props.getSortedWriteups(event.target.value)
        }
    }

    useEffect(() =>{
        if(writeupsNum !== writeups.length){
            let i = writeups.length - writeupsNum
            setWriteupsNum(i)
            setTotalWriteUps(writeups.length)
        }
    }, [writeups.length])

    const handleRefresh = () => {
        props.getWriteups();
    }

    const moreWriteups = () =>{
        props.getMoreWriteups(num);
        let i = num;
        ++i;
        setNum(i)
        if (totalWriteUps === writeups.length) {
            setHasMore(false)
    }
}

     let recentWriteUpsMarkup = !loading ? (
            writeups.map((writeup) => 
                 <WriteUp key={writeup.writeUpId} writeup={writeup} />
            )
        ) : (
            <WriteUpSkelton />
        );
    return (
        <Grid container spacing={2}>
                <Hidden smDown>
                    <Grid item lg={3} md={4} >
                        <Paper className={classes.paperWidth}/>
                        <br />
                        <Paper className={classes.paperWidth}/>
                    </Grid>
                </Hidden>
                <Grid item lg={6} md={8} xs={12}>
                    <InfiniteScroll
                        dataLength={writeupsNum}
                        next={moreWriteups}
                        hasMore={hasMore}
                        loader={<CircularProgress />}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                        refreshFunction={handleRefresh}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={50}
                        pullDownToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                        }
                        releaseToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                        }
                    >
                    <FormControl className={classes.formControl} variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={handleGenre}
                            label="Age"
                        >
                            <MenuItem value="feed">Feed</MenuItem>
                            <MenuItem value="quote">Quote</MenuItem>
                            <MenuItem value="musing">Musing</MenuItem>
                            <MenuItem value="microtale">Microtale</MenuItem>
                            <MenuItem value="dictionary">Dictionary</MenuItem>
                            <MenuItem value="poem">Poem</MenuItem>
                            <MenuItem value="letter">Letter</MenuItem>
                            <MenuItem value="story">Story</MenuItem>
                        </Select>
                    </FormControl>
                    {recentWriteUpsMarkup}
                    </InfiniteScroll>
                </Grid>
                <Hidden mdDown>
                    <Grid item lg={3}>
                    <Paper className={classes.paperWidth} />
                    </Grid>
                </Hidden>
            </Grid>
    );
}

Home.propTypes = {
    getWriteups: PropTypes.func.isRequired,
    getMoreWriteups: PropTypes.func.isRequired,
    getSortedWriteups: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

const mapStateToProps =(state) =>({
    data: state.data
});
 
export default connect(mapStateToProps, { getWriteups, getSortedWriteups, getMoreWriteups} )(withStyles(styles)(Home));