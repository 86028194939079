import React, { Component } from 'react';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import PropTypes from "prop-types";
import DraftOptionsButton from './DraftOptionsButton';
import { Link } from "react-router-dom";

//Material-UI
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";

//Redux
import { connect } from "react-redux";
import { moveDraft } from '../../redux/actions/dataActions';

const styles = (theme) =>({
    darftCard: {
        marginBottom: 20,
    },
    genreStyle:{
        color: '#808080',
    }

})

class DraftUI extends Component {
    state = {  }

    handleClick = () =>{
        this.props.moveDraft(this.props.draft, this.props.draft.draftId)
    }

    render() { 
        dayjs.extend(relativeTime)
        const { classes, draft : { head, createdAt, userName, draftId, genre},
        user: { authenticated, credentials  }} = this.props;

        const optionsButton = authenticated && userName === credentials.userName ? (
            <DraftOptionsButton draftId={draftId}/>
        ) : null

        return (
        <Grid item lg={4} md={6} xs={12}>
            <Card className={classes.darftCard}>
                <CardHeader 
                title={<Typography variant="p" className={classes.genreStyle}>{genre.toUpperCase()}</Typography>}
                action={
                    optionsButton
                    }/>
                <CardActionArea component={Link} to={`/user/${userName}/drafts/${draftId}`}>
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {head}
                    </Typography> 
                    <Typography color="textSecondary">
                        {dayjs(createdAt).format('MMM YYYY')}
                    </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button variant="contained" color="primary" onClick={this.handleClick}>
                        Post
                    </Button>
                </CardActions>
            </Card>
        </Grid>
        );
    }
}

DraftUI.propTypes = {
    user: PropTypes.object.isRequired,
    moveDraft: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapActionToProps = {
    moveDraft
}
 
export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(DraftUI));