export default {
    palette: {
        primary: {
          light: '#595f67',
          main: '#303841',
          dark: '#21272d',
          contrastText: '#fff'
        },
        secondary: {
          light: '#94b7db',
          main: '#7aa5d2',
          dark: '#557393',
          contrastText: '#fff'
        }
      },
    
      spreadThis: {
      form: {
        textAlign: 'center',
        position: 'relative',
        width: '350px',
        backgroundColor: '#ffffff',
        padding: '20px',
        borderRadius: '5px',
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: '0 3px 20px 0px rgba(0, 0, 0, 0.25)',
      },
      icon: {
        margin: '50px auto 20px 35px',
        height: '75px',
        width: 'auto',
      },
      pageTitle: {
        margin: '20px auto 20px auto',
      },
      textField: {
        margin: '10px auto 10px auto',
      },
      button: {
        marginTop: 20,
      },
      customError: {
        color: 'red',
        fontSize: '0.8rem'
      },
      progress: {
        position: 'absolute'
      }
    }
}