import React, { Component, Fragment } from 'react';
import SideBar from "../components/notes/sidebar"
import Editor from "../components/notes/editor"
import PropTypes from "prop-types"
import { Link } from "react-router-dom";

//Material-UI
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Redux
import { connect } from 'react-redux';
import { getNote, postNote, updateNote, deleteNote} from '../redux/actions/dataActions';

const styles = (theme) =>({
    paper: {
        padding: 20
      },
      buttons: {
        textAlign: 'center',
        '& a': {
          margin: '20px 10px'
        }
  },
  paperQuill: {
    height: '500px'
}
})

class Notes extends Component {
    state = {
        selectedNoteId: null,
        selectedNote: null
    }

    componentDidMount(){
        this.props.getNote();
    }

    selectNote = (note) => this.setState({
        selectedNoteId: note.noteId,
        selectedNote: note
    })

    noteUpdate =(id, noteObj) => {
        this.props.updateNote(id, noteObj);
    }

    newNote = (head) =>{
        const note = {
            head: head,
            body: ''
        }
        this.props.postNote(note)
    }

    deleteNote = (note) =>{
        const id = note.noteId;
        if(this.selectedNoteId === id) {
            this.setState({
                selectedNoteId: null,
                selectedNote: null
            })
        }
        this.props.deleteNote(id);
        this.setState({
            selectedNoteId: null,
            selectedNote: null
        })
    }

    render() { 
        const { notes } = this.props.data;
        const { classes } = this.props;

        const noteDisplay = this.props.user.authenticated ? ( 
            <Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <SideBar
                            selectedNoteId={this.state.selectedNoteId}
                            notes={notes}
                            deleteNote={this.deleteNote}
                            selectNote={this.selectNote}
                            newNote={this.newNote}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        {
                            this.state.selectedNote ?
                            <Paper className={classes.paperQuill}>
                                <Editor
                                    selectedNoteId={this.state.selectedNoteId}
                                    selectedNote={this.state.selectedNote}
                                    notes={notes}
                                    noteUpdate={this.noteUpdate}
                                    userName={this.props.match.params.userName}
                                />
                            </Paper> : null
                        }
                    </Grid>
                </Grid>
        </Fragment>
        ) : (
        <Paper className={classes.paper}>
           <Typography variant="body2" align="center">
                No notes found, please Login/SignUp
            </Typography>
            <div className={classes.buttons}>
                <Button variant="contained" color="primary" component={Link} to="/login">Login</Button>
                <Button variant="contained" color="secondary" component={Link} to="/signup">Signup</Button>
            </div>
        </Paper>
        )
        return (
            <Fragment>
               {noteDisplay}
            </Fragment>
        );
    }
}

Notes.propTypes = {
    getNote: PropTypes.func.isRequired,
}

const mapStateToProps = (state) =>({
    user: state.user,
    data: state.data
})

const mapActionToProps = {
    getNote,
    postNote,
    updateNote,
    deleteNote
}
 
export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(Notes));