import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import userReducer from "./reducers/userReducer";
import dataReducer from "./reducers/dataReducer";
import uiReducer from "./reducers/uiReducer";

const initialState = {};
const reducers = combineReducers({
    user: userReducer,
    data: dataReducer,
    UI: uiReducer
});


const store = createStore(
        reducers, 
        initialState, 
        applyMiddleware(thunk)
        );

export default store;