import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
//Material-UI
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import { connect } from "react-redux";
import { signupUser } from "../redux/actions/userActions";

const styles = (theme) =>({
    ...theme.spreadThis
});

class Signup extends Component {
    constructor(){
        super();
        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            userName: '',
            loading: false,
            errors: {}
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.UI.errors) {
            return ({ errors: nextProps.UI.errors });
          }
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        this.setState({
            loading: true
        });
        const newUserData= {
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            userName: this.state.userName
        }
        this.props.signupUser(newUserData, this.props.history);
    }

    handleChange = (event) =>{
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    render() { 
        const { classes, UI: { loading } } = this.props;
        const { errors } = this.state;
        return (
            <Grid container>
                <Grid item sm />
                <Grid item sm>
                    <div className={classes.form}>
                    <Typography variant="h3" className={classes.pageTitle}>Signup</Typography>
                    <form noValidate onSubmit={this.handleSubmit}>
                        <TextField 
                            id="email" 
                            name="email" 
                            type="email" 
                            label="Email" 
                            className={classes.textField}
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            value={this.state.email}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                        />
                        <TextField 
                            id="password" 
                            name="password" 
                            type="password" 
                            label="Password" 
                            className={classes.textField}
                            helperText={errors.password}
                            error={errors.password ? true : false}
                            value={this.state.password}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                        />
                        <TextField 
                            id="confirmPassword" 
                            name="confirmPassword" 
                            type="password" 
                            label="Confirm Password" 
                            className={classes.textField}
                            helperText={errors.confirmPassword}
                            error={errors.confirmPassword ? true : false}
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                        />
                        <TextField 
                            id="userName" 
                            name="userName" 
                            type="userName" 
                            label="Pen Name" 
                            className={classes.textField}
                            helperText={errors.userName}
                            error={errors.userName ? true : false}
                            value={this.state.userName}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            disabled={loading}
                            >
                               Signup 
                               {loading && (
                                   <CircularProgress size={30} className={classes.progress} />
                               )}
                            </Button>
                            <br />
                            <small>Already have an account? Log In <Link to="/login">here</Link></small>
                    </form>
                    </div>
                </Grid>
                <Grid item sm />
            </Grid>
        );
    }
}

Signup.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    signupUser: PropTypes.func.isRequired
}

const mapStateToProps =(state) =>({
    user: state.user,
    UI: state.UI
});

const mapActionsToProps = {
    signupUser
}
 
export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Signup));