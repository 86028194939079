// User Reducer Types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';

// UI Reducer Types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';

// Data Reducer Types
export const SET_WRITEUPS = 'SET_WRITEUPS';
export const SET_WRITEUP = 'SET_WRITEUP';
export const POST_WRITEUP = 'POST_WRITEUP';
export const LIKE_WRITEUP = 'LIKE_WRITEUP';
export const UNLIKE_WRITEUP = 'UNLIKE_WRITEUP';
export const DELETE_WRITEUP = 'DELETE_WRITEUP';
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const SET_NEWWRITEUPS = "SET_NEWWRITEUPS";

//Drafts
export const SET_DRAFTS = 'SET_DRAFTS';
export const POST_DRAFT = "POST_DRAFT";
export const SET_DRAFT = 'SET_DRAFT';
export const DELETE_DRAFT = "DELETE_DRAFT";
export const MOVE_DRAFT = "MOVE_DRAFT";

export const SET_NOTE = "SET_NOTE";
export const POST_NOTE = "POST_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";