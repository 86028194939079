import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PostWriteup from '../writeup/PostWriteup';
import PropTypes from "prop-types";

//Material-UI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import NotesIcon from '@material-ui/icons/Notes';
import DescriptionIcon from '@material-ui/icons/Description';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Tooltip from "@material-ui/core/Tooltip"
import withStyles from '@material-ui/core/styles/withStyles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

//Redux
import { connect } from 'react-redux';

const styles = {
  appbar: {
    position: "relative" ,
    width: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    
  },
  appFixed:{
    position: "fixed" ,
    width: '365px',
    top: 'auto',
    bottom: 0,
    backgroundColor: '#edf2f7',
    spacing: 100
  },
  linkButton:{
    margin: 'auto',
  },
  fabbutton: {
    bottom: 10,
  }
};

class Appbar extends Component {
    state = {  }
    render() { 
        const { classes, authenticated, credentials: { userName } } = this.props;
        let AddButton = authenticated ? (
        <PostWriteup />
        ) : (
          <Fab color="secondary" aria-label="add" className={classes.fabbutton} component={Link} to="/login">
            <AddIcon />
          </Fab>
        )

        return (
            <AppBar className={classes.appbar}>
                <Toolbar className={classes.appFixed}>
                  <Tooltip title="Home" placement="top">
                    <IconButton edge="start" aria-label="open drawer" component={Link} to="/" className={classes.linkButton}> 
                      <HomeIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Drafts" placement="top">
                    <IconButton edge="start" aria-label="open drawer" component={Link} to="/drafts" className={classes.linkButton}> 
                      <NotesIcon />
                    </IconButton>
                  </Tooltip> 
                  {AddButton}
                  <Tooltip title="Notes" placement="top">
                    <IconButton edge="end" aria-label="open drawer" component={Link} to={`/user/${userName}/notes`} className={classes.linkButton}> 
                      <DescriptionIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Profile" placement="top">
                    <IconButton edge="end" aria-label="open drawer" component={Link} to="/profile" className={classes.linkButton}> 
                      <AccountCircleIcon />
                    </IconButton>
                  </Tooltip>
                </Toolbar>
            </AppBar>
        );
    }
}

Appbar.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  credentials: state.user.credentials
})

export default connect(mapStateToProps)(withStyles(styles)(Appbar));